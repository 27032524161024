.icon {
    fill: currentColor;

    &--fill {
        width: 100%;
        min-width: 100%;
        display: block;
    }
    &--no-fill {
        fill:none;
    }
    &--loading-spinner {
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
