@import '/assets/styles/utils.scss';

.send-sms {
    margin-left: auto;
    margin-right: auto;

    &__title {
        margin-bottom: em(24, 32);
        text-align: center;
    }

    &__subtitle {
        margin-bottom: em(24, 14);
        text-align: center;
    }

    &__small-text {
        margin-top: em(8, 12);
        text-align: center;
    }

    :global(.select-search-container) {
    }
    &__input {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        border-radius: 2em;
        position: relative;
        z-index: 10;
        padding: 0.5em;
        margin-bottom: 1.25em;
        border: 1px solid var(--gray-60);
        // height: 55px;
        background: var(--primary-white);
        display: flex;
        align-items: center;

        &--isLoading {
            opacity: 0.5;
        }

        &__prefix {
            border-right: 1px solid var(--gray-60);
            height: 32px;
            display: flex;
            position: relative;
            align-items: center;
            // justify-content: center;
            margin-right: 1em;
            padding-left: 1.125em;
            // padding-right: 1.125em;
            width: 51px;
            color: black;

            > * {
                max-width: 100%;
            }

            :global(svg) {
                height: 24px;
            }
        }

        &__input {
            background: var(--primary-white);
            height: 2.4em;
            width: 100%;
            flex: 1;
            font-size: em(16);
            box-sizing: border-box;
            display: inline-block;
        }

        &__button {
            flex-shrink: 0;
            align-self: stretch;
        }

        :global {
            .select-search-container {
                box-sizing: border-box;

                // display: flex;
                // position: relative;
                // align-items: center;
                // justify-content: center;
                // margin-right: 0.125em;
                // padding-left: 0.5em;
                // padding-right: 0;
                // width: 55px;
            }

            .select-search-container *,
            .select-search-container *::after,
            .select-search-container *::before {
                box-sizing: inherit;
            }

            .select-search-input {
                font-size: em(16);
                max-width: 100%;
            }

            .select-search-is-multiple .select-search-input {
                margin-bottom: -2px;
            }

            .select-search-is-multiple .select-search-input {
                border-radius: 3px 3px 0 0;
            }

            .select-search-input[readonly] {
                cursor: pointer;
            }

            .select-search-is-disabled .select-search-input {
                cursor: not-allowed;
            }

            .select-search-select {
                background: white;
                padding: 4px 0;
                box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
                border: 2px solid var(--select-search-border);
                overflow: auto;
                max-height: 390px;
                min-width: 360px;
            }

            .select-search-container:not(.select-search-is-multiple)
                .select-search-select {
                position: absolute;
                z-index: 2;
                top: 58px;
                right: 0;
                left: 0;
                border-radius: 3px;
                display: none;
            }

            .select-search-container:not(.select-search-is-multiple).select-search-has-focus
                .select-search-select {
                display: block;
            }

            .select-search-options {
                list-style: none;
            }

            .select-search-option,
            .select-search-not-found {
                color: black;
                font-size: 1.25em;
                padding: 4px 14px;
                width: 100%;
                text-align: left;
                &:hover {
                }
            }

            .select-search-option:disabled {
                opacity: 0.5;
                cursor: not-allowed;
                background: transparent !important;
            }

            .select-search-is-highlighted,
            .select-search-option:not(.select-search-is-selected):hover {
                color: black;
                background-color: var(--sky-20);
            }

            .select-search-is-selected {
                background-color: var(--marine);
                color: white;
            }

            .select-search-group-header {
                text-transform: uppercase;
                background: var(--select-search-border);
                color: var(--select-search-subtle-text);
                letter-spacing: 0.1rem;
                padding: 10px 16px;
            }
        }
    }

    &__success,
    &__error {
        display: inline-block;
        position: fixed;
        z-index: 1000;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        color: var(--primary-white);
        padding: em(5, 14) em(24, 14);
        animation: 400ms ease-out 0s 1 slideUp;
        border-radius: em(16, 14);
    }

    &__success {
        background-color: black;
    }
    &__error {
        background-color: var(--error);
    }
}

@keyframes slideUp {
    0% {
        transform: translateX(-50%) translateY(calc(40px + 100%));
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
}
