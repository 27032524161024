@use "sass:math";
@import "/assets/styles/utils.scss";

.language-selector {
    display: block;

    &__button {
        display: flex;
        align-items: center;
        color: inherit;

        &__globe {
            width: em(32, 14);
            flex-shrink: 0;
            margin-right: em(8, 14);
        }

        &__chevron {
            width: em(16, 14);
            flex-shrink: 0;
            margin-left: em(8, 14);
        }
    }

    &__popup {
        position: fixed;
        z-index: 1000;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        background-color: var(--primary-blue);
    }

    &__logo {
        position: absolute;
        top: em(22);
        left: em(16);

        &__svg {
            height: em(28);
        }
    }

    &__close {
        position: absolute;
        top: em(20);
        right: em(20);
        background-color: white;
    }

    &__title {
        margin-bottom: em(40, 32);
        text-align: center;
    }

    &__list {
        margin-right: auto;
        margin-left: auto;
        width: em(440);
        max-width: 100%;
    }

    &__item {
        margin-bottom: em(16);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        color: black;
        justify-content: flex-start;
        padding: em(14) em(12);
        border-radius: 10000px;

        &:not(&--active):hover {
            background-color: rgba(#fff, 0.6);
        }

        &--active {
            pointer-events: none;
        }

        svg {
            width: em(24, 14);
            margin-right: em(8);
            border-radius: 50%;
        }
    }
}
