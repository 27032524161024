@use "sass:math";
@import "/assets/styles/utils.scss";

.mobile-nav {
    $self: &;
    background-color: white;
    position: fixed;
    z-index: 1000;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    opacity: 0;
    color: var(--primary-black);

    &__panel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__panel--primary {
        transform: translateX(0%);

        #{$self}--submenu-open & {
            transform: translateX(-100%);
        }
    }

    &__panel--secondary {
        transform: translateX(100%);

        #{$self}--submenu-open & {
            transform: translateX(0);
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--margin-spacing);

        > * {
            flex-basis: 25%;
        }

        // Segment selector
        > ul {
            border-color: var(--gray-60);
        }
    }

    &__main {
        padding: 1.5em;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &--flex-start {
            justify-content: flex-start;
        }
    }

    &__bottom {
        padding: 1.5em;
    }


    &__active-submenu {
        color: var(--gray-90);
        font-weight: 500;
        font-size: em(14);
        flex-grow: 1;
    }
    &__close {
        float: right;
        background-color: var(--gray-30);
        border-radius: 100%;
        svg {
            float: right;
            display: block;
            width: em(22);
            padding: em(6);
        }
    }

    &__list {
        > li {
            padding-bottom: em(16);
            margin-bottom: em(16);
            border-bottom: 1px solid var(--gray-60);

            > * {
                display: flex;
                justify-content: space-between;
                font-weight: 400;
                width: 100%;
                text-align: left;
                align-items: center;

                > svg {
                    height: 1em;
                    // align right, and vertically center
                    position: relative;
                    top: 0.1em;
                    margin-left: 0.8em;
                }
            }

            a {
               position: relative;
                p { // Mark as New label
                    font-size: .7em;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    margin-left: .4em;
                    transform: translateY(-50%);
                    padding: .2em .65em;
                    z-index: 1;
                    color: var(--primary-white);
                    border-radius: 1.1428571429em;
                    border-bottom-left-radius: 0;
                }
            }
        }
        strong {
            display: block;
            margin-bottom: em(24, 16);
            font-weight: 600;
            margin-top: em(48, 14);
        }

        li:first-child strong {
            margin-top: 0;
        }
    }

    &__sub-list {
        font-size: 1em;
        > li {
            margin-bottom: em(40);
        }
    }
}
