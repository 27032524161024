@use 'sass:math';
@import '/assets/styles/utils.scss';

.footer {
    display: block;
    margin-top: var(--module-spacing);

    &__navigation {
        position: relative;
        padding-top: em(32);
        padding-bottom: 4em;
        color: var(--primary-white);
        background-color: var(--primary-black);

        @media (min-width: 768px) {
            padding-top: em(40);
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: em(40);
        border-bottom: 1px solid var(--charcoal);

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__languages {
        > :hover {
            opacity: 0.85;
        }
        @media (min-width: 768px) {
            margin-left: 40px;
            padding-left: 40px;
            border-left: 1px solid var(--charcoal);
        }
        @media (max-width: 767px) {
            display: block;
            width: 100%;
            padding-top: em(40);
            margin-top: em(40);
            border-top: 1px solid var(--charcoal);
        }
    }

    &__logo {
        display: block;
        text-align: center;
        margin-right: 40px;

        svg {
            height: em(35);
            fill: var(--primary-white);
            vertical-align: bottom;
        }
    }

    &__segment-selector {
        background-color: transparent;
        border-color: var(--gray-90);

        @media (max-width: 767px) {
            margin: 40px 0;
        }

        &:hover a {
            color: black;
        }

        [class*='__item__'] {
            color: white;

            &[class*='__item--active'] {
                color: black;
            }
        }
    }

    &__inner {
        margin-top: 3em;
    }

    &__legal {
        color: var(--cloud);
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 3em;
        margin-bottom: 4em;

        @media (max-width: 767px) {
            flex-direction: column;

            ul {
                padding: em(40) 0;
                border-bottom: 1px solid var(--charcoal);
            }
        }
        @media (min-width: 768px) {
            padding-top: em(40);
            border-top: 1px solid var(--charcoal);
            justify-content: center;
        }
    }

    &__segment {
        display: flex;

        @media (min-width: 768px) {
            margin-right: em(80);
        }

        &__item {
            display: block;
            margin-right: em(16, 14);
            padding-top: em(5, 14);
            padding-bottom: em(3, 14);
            opacity: 0.5;
            border-bottom: solid 2px transparent;

            &--active {
                opacity: 1;
                border-color: var(--primary-white);
            }
        }
    }

    &__app {
        @media (min-width: 768px) {
            display: flex;
            margin-left: auto;
        }
        @media (max-width: 767px) {
            a img {
                width: 150px;
            }
        }

        a:not(:nth-child(1)) {
            margin-left: 1em;
        }
        a {
            display: block;

            img {
                display: block;
            }
        }

        &__icon {
            height: 2.5em;
            vertical-align: bottom;
        }
    }

    /* General link styling */
    &__link {
        margin-bottom: 1em;
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 0.874em;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }

        & > a {
            color: var(--primary-white);
            transition: color 0.25s ease-out;
            position: relative;
            display: inline-block;

            &:hover,
            &:focus {
                color: darken(#fff, 25%);
            }

            p {
                display: inline;
                font-size: 0.7em;
                position: relative;
                bottom: 0.9em;
                margin-left: 0.4em;
                padding: 0.2em 0.65em;
                z-index: 1;
                color: var(--primary-white);
                border-radius: 1.1428571429em;
                border-bottom-left-radius: 0;
            }
        }
    }

    &__nav {
        $self: &;
        $pre-tablet: 550px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media (min-width: $pre-tablet) {
            flex-direction: row;
        }

        & > li {
            flex: 1;
            margin-bottom: 0;
            margin-right: 0;

            &:nth-child(1) {
                flex: 0 0 100%;

                @media (min-width: 1240px) {
                    flex: 0 0 60%;
                }
            }
            &:nth-child(2),
            &:nth-child(3) {
                flex: 0 0 50%;

                @media (min-width: 768px) {
                    flex: 0 0 calc(100% / 3);
                }

                @media (min-width: 1240px) {
                    flex: 0 0 20%;
                }
            }

            & > ul {
                display: flex;
                flex-direction: column;

                & > li {
                    @media (min-width: $pre-tablet) {
                        &:nth-child(2n),
                        &:nth-child(2n + 1) {
                            flex: 0 0 50%;
                        }
                    }

                    @media (min-width: 768px) {
                        flex: 1 !important;
                    }
                }
            }
        }

        &__link-group-ul {
            &--collector-inside {
                @media (min-width: $pre-tablet) {
                    flex-direction: row !important;
                    flex-wrap: wrap;
                }
            }
        }

        // need these to enable optional classes
        &__link-collector-inside,
        &__link-collector-wrapper,
        &__link-collector {
            position: relative;
        }

        &__link-group {
            $link-group-margin: 60;
            position: relative;
            margin-bottom: em($link-group-margin, 16);

            @media (min-width: $pre-tablet) {
                margin-right: em(calc($link-group-margin - 10), 16);
            }

            &__title {
                &--large,
                &--small {
                    display: block;
                }

                &--large {
                    display: block;
                    margin-top: em(calc(48 - $link-group-margin));
                    margin-bottom: em(28);
                    color: var(--primary-white);
                    opacity: 0.5;

                    @media (min-width: $pre-tablet) {
                        margin-top: 0;
                    }
                }

                &--small {
                    margin-bottom: em(14);
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: var(--primary-white);
                }
            }
        }
    }
}
