@import "/assets/styles/utils.scss";

.app-store {

    &__horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0px 10px;

        .app-store__title {
            width: 100%;
        }

    }
    &__vertical {
        display: block;
    }

    &__title {
        text-align: center;
        margin-bottom: em(24, 32);
    }

    &__link {
        display: block;
        text-align: center;

        svg {
            height: em(52);
        }
    }
}
