@use "sass:math";
@import "/assets/styles/utils.scss";

.header {
    $self: &;
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    --color: black;
    border-bottom: 1px solid var(--gray-60);

    :global(.segment-business) & {
        --color: white;
        background-color: black;
        border-bottom: 1px solid black;

        :global([class*="DownloadOverlay_download-overlay__button"] ) {
            --hover-color: white;
            &:hover {
                color: black;
            }
        }
    }

    &--dark {
        --color: black;
        :global(.segment-business) & {
            --color: white;
        }
    }

    &--light {
        --color: black;
    }

    &--transparent-bg-black-fg, &--transparent-bg-white-fg {
        transition: all .4s ease-in-out;
    }
    :global(body:not(.scrolled-past-top-module)) & {
        &--transparent-bg-black-fg {
            background-color: transparent;
            --color: black;
            border-bottom: none;
        }
        &--transparent-bg-white-fg {
            background-color: transparent;
            --color: white;
            border-bottom: none;
        }
    }


    &__inner {
        display: flex;
        align-items: center;
        z-index: 1;
        height: var(--header-height);
    }

    &__logo {
        color: var(--color);

        svg {
            display: block;
            height: em(35);
            width: auto;
            @media (max-width: 768px) {
                height: em(28);
            }
        }

        &--business {
            svg {
                height: 3em;
                @media screen and (max-width: 768px) {
                    height: 2.5em;
                }
            }
        }
    }

    &__nav {
        display: none;
        height: 100%;
        align-items: center;
        margin-left: em(48);
        margin-right: auto;

        @media (min-width: 1024px) {
            display: flex;
        }
        @media (min-width: 1280px) {
            margin-left: em(80);
        }
    }

    &__segment {
        margin-left: 0;
        margin-right: em(20);

        @media (min-width: 1280px) {
            margin-right: em(36);
        }
    }

    &__menu {
        display: flex;
        height: 100%;

        &__item {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            &--big {
                position: static;
            }

            &:first-child > *:first-child {
                margin-left: 2em;
                @media (min-width: 1280px) {
                    margin-left: 3em;
                }
            }
            &:last-child > *:first-child {
                margin-right: 2em;
                @media (min-width: 1280px) {
                    margin-right: 3em;
                }
            }

            > *:first-child {
                color: var(--color);
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                margin-right: 1em;
                margin-left: 1em;
                @media (min-width: 1280px) {
                    margin-right: 1.5em;
                    margin-left: 1.5em;
                }
            }

            // The first menu item "Vi tilbyder" should be positioned absolute, in relation to the wide .header
            // while the rest should be positioned relative, in relation to the individual menu item
            // &:not(:first-child) {
            //     position: relative;
            // }
            // &:hover {
            //     :global(> [class*="popup"]) {
            //         display: block;
            //     }
            // }

            &--active {
                > div > button > svg {
                    transform: rotate(-180deg);
                }
                > *:first-child {
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        right: 0;
                        height: 4px;
                        background-color: var(--accent);
                        @media (min-width: 1280px) {
                            // right: 1em;
                            // left: 1em;
                        }
                    }
                }
            }
        }



    }

    &__menu-link {


        &:hover {


        }
    }

    &__right-side {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 0;
    }

    &__login {
        color: var(--color);
        margin-right: 2em;
        @media (max-width: 768px) {
            display: none;
        }
    }

    &__open-menu {
        margin-left: 1em;
        margin-right: 0;
        display: flex;
        background: var(--fog);
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        color: black;
        width: 34px;
        height: 34px;

        @media (min-width: 1024px) {
            display: none;
        }

        svg {
            width: 18px;
            color: black;
            display: block;
        }
    }
    &__backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.30);
        z-index: 10;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s;

        &--active {
            opacity: 1;
        }
    }
}
