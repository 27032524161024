.image {
    display: block;
    position: relative;
    overflow:  hidden;
    width: 100%;
    height: 100%;

    &__blur {
        top: -1em;
        bottom: -1em;
        left: -1em;
        right: -1em;
        position: absolute;
        filter: blur(1em);
        background-size: cover;
        background-position: center;
        transform: translateZ(0);
    }

    &__src {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
        transition: opacity .25s .15s;
    }
}
