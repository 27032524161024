@use "sass:math";
@import "/assets/styles/utils.scss";

.login-overlay {
    display: block;
    position: absolute;

    &__popup {
        position: fixed;
        z-index: 1000;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: black;
        animation: fadein 0.3s;
        animation-fill-mode: forward;
        &--closed {
            display: none;
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &__logo {
        position: absolute;
        top: em(22);
        left: em(16);

        &__svg {
            height: em(28);
        }
    }

    &__close {
        position: absolute;
        top: 1em;
        right: 1em;
    }

    &__container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__actions {
        display: flex;
        width: 100%;
        height: 470px;
        margin: 26px auto 0;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 768px) {
            width: 716px;
            height: 227px;
            margin: 64px auto 0;
            flex-direction: row;
        }
    }

    &__cta {
        width: 350px;
        height: 227px;
        padding: 32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: var(--primary-white);

        & > h2 {
            font-size: 24px;
        }

        & > p {
            font-size: 14px;
        }

        & > button {
            padding: 9px 34px;
            background: var(--marine);
            border-radius: 100px;
            color: var(--primary-white);
        }
    }



}
