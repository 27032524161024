.some-links-block {
    display: block;
    line-height: 0;
    &__item {
        display: inline-block;
        margin: 0 0.5em;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        svg {
            width: 2.375em;
            height: 2.375em;
        }
    }
}
