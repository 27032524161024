@use 'sass:math';
@import '/assets/styles/utils.scss';

.segment-selector {
    transition: background-color ease-in 200ms;
    $self: &;
    isolation: isolate;
    display: flex;
    // background-color: white;
    border: 1px solid var(--gray-30);
    border-radius: 5em;
    align-self: flex-start;
    position: relative;

    &--dark {
        background-color: rgba(black, 0.05);
        #{$self}__item {
            color: white;

            &--active {
                color: black;
                &:before {
                    border-color: white;
                }
            }

            &:hover {
                color: black;
            }
        }
    }

    &__link {
        display: block;
        padding: em(5, 14) em(20, 14);
        color: var(--color);
    }

    &__item {
        transition: opacity 200ms ease;
        z-index: 1;
        align-self: flex-start;
        color: black;
        border-radius: 5em;
        opacity: 1;
        position: relative;

        &:not(&--active) {
            &:first-child {
                margin-right: -25px;
                padding-right: 25px;
            }
            &:last-child {
                margin-left: -25px;
                padding-left: 25px;
            }
        }

        &:hover {
            opacity: 1;
            background-color: var(--gray-30);
            --color: black;
        }

        &--active {
            opacity: 1;
            z-index: 2;
            position: relative;
            a {
                position: relative;
                z-index: 2;
                color: black;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: -1px;
                right: 0;
                top: -1px;
                bottom: -1px;
                background-color: white;
                border: 1px solid var(--gray-30);
                background-color: var(--gray-30);
                border-radius: 5em;
                z-index: 1;
            }
            &:hover:before {
                background-color: var(--gray-30);
            }

            .segment-selector__link {
                padding-left: em(30, 14);
                padding-right: em(30, 14);
            }
        }
    }
}
