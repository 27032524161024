.container {
    max-width: 1648px;
    width: calc(100% - (2 * var(--margin-spacing)));
    margin-left: var(--margin-spacing);
    margin-right: var(--margin-spacing);

    @media (min-width: 1600px) {
        margin-left: auto;
        margin-right: auto;
    }

    &--no-margin {
        max-width: 1728px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
