.video-smart {
    position: relative;
    overflow: hidden;

    iframe {
        position: absolute;
        height: var(--aspect-ratio-height);
        width: var(--aspect-ratio-width);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
