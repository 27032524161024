@import "/assets/styles/utils.scss";
@import "/assets/styles/typography.scss";

.page {
    padding-top: var(--header-height);
    margin-bottom: em(200);

    [role="button"],
    a {
        display: inline-flex;
        text-decoration: underline;
        font-weight: 500;
    }

    h1 {
        @extend .heading-300;
    }
    h2 {
        @extend .heading-200;
    }
    h3 {
        @extend .heading-100;
    }
    h4 {
        @extend .heading-50;
    }

    p,
    ul,
    ol,
    li {
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    li {
        padding-left: 0.25em;
        margin-left: 1.5em;
    }

    ul li {
        list-style-image: url("data:image/svg+xml;utf8,<svg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 1.00012L4.66663 8.00018L1 4.50015' stroke='rgb(0,0,249)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    }

    ol li {
        list-style-type: decimal;
    }
    em {
        font-style: italic;
    }
    strong {
        font-weight: 500;
    }
}
