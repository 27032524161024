.video-lightweight {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    video {
        // position: absolute;
        // height: var(--aspect-ratio-height);
        // width: var(--aspect-ratio-width);
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        z-index: 10;

        // Remove bugged black border around video in Safari
        // https://stackoverflow.com/a/53779216
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
    }
}
