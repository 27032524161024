.main {
    :global(.doc-inAppPage) & {
        --header-height: 0;
    }
    // margin-top: var(--header-height);
    position: relative;
    z-index: 1;

    &--no-top-margin {
        margin-top: 0;

        :global(> div:first-child [class*="Container_container"]) {
            padding-top: var(--header-height);
            margin-top: calc(-1 * var(--header-height));
        }
    }
}
// SAS Stratosphere background
.news-ribbon {
    // background-image: url('https://cdn.sanity.io/images/inp43svr/production/9bb1ee6f8398cfda127532accbf2be2673c1ab0f-1288x1442.png?w=100&h=100&max-h=2000&q=90&auto=format&rect=0,1020,600,420');
    // background-repeat: no-repeat;
    // background-position: bottom left;
    // background-size: 100% auto;
    position: relative;
    [class*="RibbonModule_ribbon-module__static__inner__text"] {
        @media (min-width: 768px) {
            position: relative;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 50px;
            height: 40px;
            background-image: url(https://cdn.sanity.io/images/inp43svr/production/9bb1ee6f8398cfda127532accbf2be2673c1ab0f-1288x1442.png?w=100&h=100&max-h=2000&q=90&auto=format&rect=0,1060,580,380);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            bottom: 8px;
            right: 8px;
            transition: transform 0.3s ease;
            @media (min-width: 768px) {
                left: 103%;
                top: -7px;
            }
            @media (max-width: 768px) {
                animation: hoverUpDown 0.6s ease-in-out infinite alternate;
            }
        }
    }
    &:hover [class*="RibbonModule_ribbon-module__static__inner__text"]:after {
        animation: hoverUpDown 0.6s ease-in-out infinite alternate;
    }
}

@keyframes hoverUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-3px);
    }
}
.dark-theme {
    position: relative;
    color: var(--primary-white);
    background-color: black;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--page-theme-background-color);
    }
}

.has-background {
    background-size: 100% auto;
    background-repeat: repeat-y;
    background-position-y: top;
    background-position-x: center;
}

.shareit-theme {
    :root.button--pill {
        background-color: black;
    }
}
.doc-inAppPage {
    :root {
        --header-height: 0;
    }
}
